import React from "react"

import Layout from "../components/Layout"
import NotFound from "../views/NotFound";

const NotFoundPage = function ({ location }) {
    const locale = /^\/en\//.test(location.pathname) ? "en" : "it";
    return (
        <Layout location={location} locale={locale} >
            <NotFound />
        </Layout>
    );
};

export default NotFoundPage;
