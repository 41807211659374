import React, { Fragment } from 'react';

import SEO from "../components/SEO"
import NavBar from "../components/common/NavBar";
import Jumbotron from "../components/common/Jumbotron";

import { withTranslate } from "../components/translate";
import processText from './processText';

const NotFound = withTranslate(function ({ localePath, translate }) {
    return (
        <Fragment>
            <SEO title={translate("notFound.head.title")} />
            <NavBar localePath={localePath} translate={translate} />
            <header className="container py-3 mt-4" style={{ textAlign: "center" }}>
                <div className="intro tagline mt-3">
                    <h1>
                        {translate("notFound.title")}
                    </h1>
                </div>
            </header>
            <Jumbotron color="#fff" className="text-center mb-5">
                {translate("notFound.text").map((part) => (
                    <p>{processText(part)}</p>
                ))}
            </Jumbotron>
        </Fragment>
    );
});

export default NotFound;
